<template>
    <div v-if="object" class="borderBottom mb-1">
        <div class="answer-content py-1" style="white-space: pre-wrap">
            <b style="font-weight: 900 !important;">{{object.author}}: </b>
            {{object.question}}
        </div>
        <div class="content mb-1" style="white-space: pre-wrap">
            <b style="font-weight: 900 !important;">Odgovor:</b>
            {{object.answer}}
        </div>
    </div>
</template>

<script>
    export default {
        props:{
            object:{
                type: Object,
                default: null
            }
        }
    }
</script>
<style scoped>
    .borderBottom{
        border-bottom: 1px solid black;
    }

    .content, .answer-content{
        font-size: 17px !important;
    }
</style>