<template>
    <div style="position: relative;">
        <div class="background" />
        <div class="row" style="background: white;">
            <div class="col-md-4 main-title">
                Pogosta vprašanja
            </div>
            <div class="col-md-8 mt-2 mt-md-0" v-if="data">
                <faq-component v-for="(item, id) in data" :key="'vprasanja_' + id" :object="item" :id="'faq_' + item.id"/>
            </div>
        </div>
    </div>
</template>

<script>
    import FaqComponent from './FaqComponent.vue'
    export default {
        components: { FaqComponent },
        data() {
            return {
                data: null
            }
        },
        methods: {
            goto(id) {
                const element = document.getElementById(`faq_${  id}`)
                if (element) {
                    element.style.background = '#72a5d880'
                    element.scrollIntoView({ behavior: 'smooth', block: 'center' })
                }
            },
            async loadData() {
                try {
                    const res = await this.$http.get('/api/user/v1/faq')
                    this.data = res.data
                } catch (err) {
                    this.$printError(err)
                }
            }
        },
        async mounted() {

            await this.loadData()

            if (this.$route.query.question_id) {
                this.goto(this.$route.query.question_id)
            } else {
                this.$scrollToTop()
            }
        }
    }
</script>

<style scoped>
    .background{
        position: absolute;
        top: 50px;
        height: 150px;
        width: 9999px;
        margin-left: -2000px !important;
        margin-right: -2000px !important;
        background: linear-gradient(#72a5d84b, white) !important;
        z-index: -1;
    }

    .main-title{
        font-size: 3rem;
        font-weight: 900;
    }

    .title-content{
        font-weight: bold;
        font-size: 1.3rem;
    }

    .borderBottom{
        border-bottom: 1px solid black;
    }

</style>